<template>
  <div class="article_add">
    <!-- <div class="add-title">创建新文章</div> -->
    <div class="add-form">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        size="mini"
      >
        <el-row>
          <el-col :span="7">
            <el-form-item label="标题：" prop="title">
              <el-input
                v-model.trim="form.title"
                maxlength="32"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="关键词：" prop="keywords">
              <el-input
                v-model.trim="form.keywords"
                maxlength="16"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="描述：" prop="description">
              <el-input
                v-model.trim="form.description"
                maxlength="128"
                show-word-limit
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
            <el-form-item label="文章分类：" prop="class">
              <el-select v-model="form.class">
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="(item, key) in classList"
                  :key="key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文章类型：">
              <el-radio-group v-model="form.type">
                <el-radio label="1">文字</el-radio>
                <el-radio label="2">视频</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="状态：">
              <el-radio-group v-model="form.status">
                <el-radio label="1">开启</el-radio>
                <el-radio label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="热门文章：">
              <el-radio-group v-model="form.hot">
                <el-radio label="1">开启</el-radio>
                <el-radio label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="封面图片：" prop="banner">
              <el-upload
                class="avatar-uploader"
                :action="$baseurl + '/file_upload'"
                :show-file-list="false"
                :on-success="fileUploadApi"
                :before-upload="beforeAvatarUpload"
                :data="fileData"
                :with-credentials="true"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="15">
            <el-form-item label="内容：" v-if="form.type != 2">
              <editor-bar
                v-model="detail"
                :isClear="isClear"
                @change="change"
              ></editor-bar>
            </el-form-item>
            <!-- 视频 -->
            <el-form-item label="上传视频：" v-else>
              <div>
                <div class="pic_img">
                  <div class="pic_img_box">
                    <el-upload
                      class="avatar-uploader"
                      :action="$baseurl + '/file_upload'"
                      v-bind:data="fileData2"
                      v-bind:on-progress="uploadVideoProcess"
                      v-bind:on-success="handleVideoSuccess"
                      v-bind:before-upload="beforeUploadVideo"
                      v-bind:show-file-list="false"
                      :with-credentials="true"
                    >
                      <video
                        v-if="videoForm.showVideoPath != '' && !videoFlag"
                        v-bind:src="videoForm.showVideoPath"
                        class="avatar video-avatar"
                        controls="controls"
                      >
                        您的浏览器不支持视频播放
                      </video>
                      <i
                        v-else-if="videoForm.showVideoPath == '' && !videoFlag"
                        class="el-icon-plus avatar-uploader-icon"
                      ></i>
                      <el-progress
                        v-if="videoFlag == true"
                        type="circle"
                        v-bind:percentage="videoUploadPercent"
                        style="margin-top: 7px"
                      ></el-progress>
                    </el-upload>
                  </div>
                </div>
              </div>
              <p class="Upload_pictures">
                <span></span>
                <span>最多可以上传1个视频，建议大小50M，推荐格式mp4</span>
              </p>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row type="flex" justify="center" class="btn">
      <el-button type="primary" @click="addBtn">提交</el-button>
    </el-row>
  </div>
</template>
<script>
import EditorBar from "@/components/wangeditor";
import { getartClass, reviewart, getart } from "@/api/article.js";
export default {
  components: {
    EditorBar,
  },
  data() {
    return {
      rules: {
        title: [
          {
            required: true,
            min: 2,
            max: 32,
            message: "长度在 2 到 32 个字符",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "请填写描述",
            trigger: "blur",
          },
        ],
        keywords: [
          {
            required: true,
            message: "请填写关键字",
            trigger: "blur",
          },
        ],
        class: [
          {
            required: true,
            message: "请选择分类",
            trigger: "blur",
          },
        ],
        banner: [
          {
            required: true,
            message: "上传封面图片",
            trigger: ["blur", "change"],
          },
        ],
      },
      isClear: false,
      detail: "",
      form: {
        class: "",
        type: "1",
        title: "",
        description: "",
        content: "",
        status: "1",
        keywords: "",
        hot: "0",
        banner: "",
      },
      imageUrl: "",
      fileData: {
        type: 8,
        attribution_id: 1, // 当前 用户的id
      },
      fileData2: {
        type: 7,
        attribution_id: 1, // 当前 用户的id
      },
      classList: [],
      //   --------------
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: "",
      //进度条的进度，
      isShowUploadVideo: false,
      //显示上传按钮
      videoForm: {
        showVideoPath: "",
      },
      infoData: {},
      issta: 0,
    };
  },
  created() {
    this.init();
    if (this.$route.query.id) {
      this.loadEidtData();
    }
  },
  methods: {
    // 页面初始数据加载
    init() {
      let data = {
        pid: 0,
        page: 0,
        pagesize: 999,
      };
      getartClass(data).then((res) => {
        this.classList = res.data;
      });
    },
    // 编辑数据渲染
    loadEidtData() {
      let data = {
        id: this.$route.query.id,
      };
      getart(data).then((res) => {
        console.log(res.data[0]);
        this.infoData = res.data[0];
        // -------------数据渲染---------------
        let {
          banner,
          bannerurl,
          content,
          create_time,
          description,
          hot,
          id,
          keywords,
          like,
          reads,
          status,
          title,
          type,
          update_time,
          videoid,
          videourl,
        } = this.infoData;
        let clas = res.data[0].class;
        this.detail = content;
        this.form.title = title;
        this.form.type = type + "";
        this.form.hot = hot + "";
        this.form.keywords = keywords;
        this.form.status = status + "";
        this.form.description = description;
        this.form.class = clas;
        this.form.banner = banner;
        this.form.videoid = videoid;
        this.imageUrl = bannerurl;
        this.videoForm.showVideoPath = videourl;
      });
    },
    // 文件上传成功的狗子
    fileUploadApi(response, file) {
      this.form.banner = response.data.id;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 图片上次校验
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "jpeg" ||
        testmsg === "JPG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      const isLt50M = file.size / 1024 / 1024 < 2;
      if (!extension) {
        this.$message({
          message: "上传图片只能是jpg / png / bpm格式!",
          type: "error",
        });
        return false;
      }
      //console.log(file);
      if (!isLt50M) {
        this.$message({
          message: "上传文件大小不能超过 2MB!",
          type: "error",
        });
        return false;
      }
      return extension || isLt50M;
    },
    change(val) {
      this.issta = 1;
      this.form.content = val;
    },
    // 提交
    addBtn() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let iscode = 0;
          if (this.$route.query.id) {
            iscode = 1;
            this.form.id = this.$route.query.id;
          }
          if (this.issta == 0) {
            this.form.content = this.detail;
          }
          reviewart(this.form).then((res) => {
            if (res.code == 400200) {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "success",
              });
              this.$router.go(-1);
            }
          });
        } else {
          this.$message({
            showClose: true,
            message: "请检查内容，是否填写完整",
            type: "warning",
          });
          return false;
        }
      });
    },
    // ---------------
    //上传前回调
    beforeUploadVideo(file) {
      var fileSize = file.size / 1024 / 1024 < 50;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
          "video/mov",
        ].indexOf(file.type) == -1
      ) {
        layer.msg("请上传正确的视频格式");
        return false;
      }
      if (!fileSize) {
        layer.msg("视频大小不能超过50MB");
        return false;
      }
      this.isShowUploadVideo = false;
    },
    //进度条
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true;
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },
    //上传成功回调
    handleVideoSuccess(res, file) {
      this.isShowUploadVideo = true;
      this.videoFlag = false;
      this.videoUploadPercent = 0;

      //前台上传地址
      if (file.status == "success") {
        this.form.videoid = res.data.id;
        this.videoForm.showVideoPath = URL.createObjectURL(file.raw);
        console.log(file);
      } else {
        layer.msg("上传失败，请重新上传");
      }

      //后台上传地址
      //   if (res.Code == 0) {
      //     this.videoForm.showVideoPath = res.Data;
      //   } else {
      //     layer.msg(res.Message);
      //   }
      console.log(res);
    },
  },
};
</script>
<style lang="less">
.article_add {
  margin-top: 30px;
  // 头像
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 250px;
    height: 180px;
    line-height: 180px;
    text-align: center;
  }
  .avatar {
    width: 250px;
    height: 180px;
    display: block;
  }
  .avatar-uploader {
    // display: flex;
    // justify-content: center;
  }
  .add-title {
    padding: 20px 10px;
    color: rgb(32, 32, 32);
    font-size: 20px;
    font-weight: 700;
  }
  .add-form {
    .el-input {
      width: 300px;
    }
  }
  .btn {
    margin-top: 100px;
    .el-button {
      width: 200px;
    }
  }
}
</style>